import React from "react";
import Hero from "../components/Hero";
import Footer from "../components/layout/Footer";
import ContactFrom from "../components/contact/ContactForm";
export default function CestQuoi() {
  const heroContent = {
    title: "VOTRE BUREAU TOUJOURS PLUS PROCHE DE VOUS",
    paragraph:
      "Pour vous simplifier la vie et que vous soyez pleinement concentré(e) sur votre travail, on a pensé à un service de coworking de proximité, simple à utiliser.",
    bgImage:
      "https://images.unsplash.com/photo-1572021335469-31706a17aaef?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80",
    cta: {
      ctaTitle: "JE VEUX EN SAVOIR PLUS",
      ctaLink: "",
    },
  };
  return (
    <>
      <Hero data={heroContent} />

      <section id="partner">
        <div className="container">
          <h2>Notre PARTENAIRE</h2>
          <p>
            Afin de vous faciliter la vie et de rendre nos espaces le plus
            accessible possible, nous avons sélectionné notre partenaire, à qui
            nous avons confié la délicate mission de l’enregistrement des
            réservations.
            <br /> Néonomade et Bird Office sont des plateformes de réservation
            d’espace dédiées au cotravail et à la recherche et à la location de
            bureaux partagés. Lorsque vous sélectionnez un espace Nomade, vous
            êtes redirigé sur l’une de ces deux plateformes ? C’est normal !
          </p>
          <div className="partner-card">
            <div className="partner-image">
              <img
                src="https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
                alt="theoffice"
              />
            </div>
            <a href="https://theoffice.digital" className="partner-link">
              https://theoffice.digital
            </a>
          </div>
        </div>
      </section>
      <ContactFrom />
      <Footer />
    </>
  );
}
